import React, { FC } from 'react';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { Toast, ToastPlacement, ToastSkin } from 'wix-ui-tpa/cssVars';
import { classes } from '../../Widget.st.css';

interface Props {
  success: boolean;
  isSubscribed?: boolean;
  onClose(): void;
}

export const SubscriptionToast: FC<Props> = ({
  success,
  isSubscribed,
  onClose,
}) => {
  const { t } = useTranslation();
  const bi = useBi();

  return (
    <Toast
      className={classes.subscriptionToast}
      placement={ToastPlacement.inline}
      skin={success ? ToastSkin.success : ToastSkin.error}
      shouldShowCloseButton
      onClose={onClose}
    >
      {t(
        success
          ? isSubscribed
            ? 'app.widget.marketing-emails.subscription-toast.success.subscribed'
            : 'app.widget.marketing-emails.subscription-toast.success.unsubscribed'
          : 'app.widget.marketing-emails.subscription-toast.error',
      )}
    </Toast>
  );
};
