import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  backgroundColor: StyleParamType.Color;
  primaryFont: StyleParamType.Font;
  titleFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
  buttonTextColor: StyleParamType.Color;
  buttonOpacityAndColor: StyleParamType.Color;
  borderColor: StyleParamType.Color;
  borderWith: StyleParamType.Number; // the typo is intended :(
};

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  primaryFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 20,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonOpacityAndColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  borderWith: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
});
