import React, { FC, useEffect, useState } from 'react';
import {
  useBi,
  useEnvironment,
  useTranslation,
  type WidgetProps,
} from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa/cssVars';
import {
  Channel,
  Type,
} from '@wix/ambassador-notifications-v1-notification-preferences/types';
import { membersAreaNotificationSettingsPageFinishLoading } from '@wix/bi-logger-identity-data/v2';
import { st, classes } from './Widget.st.css';
import { SubscriptionStatus } from './components/subscription-status';
import { PreferencesList } from './components/preferences-list';
import type { ChannelPreferences } from '../../../types';
import { SubscriptionToast } from './components/subscription-toast';

export type ControllerProps = {
  startLoadingTime: number;
  isSubscribed: boolean;
  onChangeSubscription(isSubscribed: boolean): Promise<void>;
  channelPreferences?: ChannelPreferences;
  onChangePreference(id: string, mute: boolean): void;
};

export const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const { t } = useTranslation();
  const { isMobile, isSSR } = useEnvironment();
  const bi = useBi();

  useEffect(() => {
    if (!isSSR && props.startLoadingTime) {
      const loading_time = Number(new Date()) - props.startLoadingTime;
      bi.report(
        membersAreaNotificationSettingsPageFinishLoading({
          loading_time,
        }),
      );
    }
  }, [bi, props.startLoadingTime, isSSR]);

  const [isSubscribed, setIsSubscribed] = useState(props.isSubscribed);
  const [showSubscriptionSuccessToast, setShowSubscriptionSuccessToast] =
    useState(false);
  const [showSubscriptionErrorToast, setShowSubscriptionErrorToast] =
    useState(false);

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook="PreferencesOoi-wrapper"
    >
      {showSubscriptionSuccessToast && (
        <SubscriptionToast
          success={true}
          isSubscribed={isSubscribed}
          onClose={() => setShowSubscriptionSuccessToast(false)}
        />
      )}
      {showSubscriptionErrorToast && (
        <SubscriptionToast
          success={false}
          onClose={() => setShowSubscriptionErrorToast(false)}
        />
      )}
      <div>
        <Text tagName="H2" className={st(classes.titleText, classes.title)}>
          {t('app.widget.title')}
        </Text>
        <Text tagName="H5" className={st(classes.plainText, classes.subtitle)}>
          {t('app.widget.subtitle')}
        </Text>
      </div>
      <PreferencesList
        preferencesByApp={props.channelPreferences?.preferencesByApp}
        type={Type.PROMOTIONAL}
        onChangeItem={props.onChangePreference}
        channel={Channel.EMAIL}
        disabled={!isSubscribed}
      >
        <SubscriptionStatus
          isSubscribed={isSubscribed}
          onClick={async () => {
            const newStatus = !isSubscribed;
            setIsSubscribed(newStatus);
            try {
              await props.onChangeSubscription(newStatus);
              setShowSubscriptionSuccessToast(true);
              setTimeout(() => setShowSubscriptionSuccessToast(false), 5000);
            } catch (e) {
              setShowSubscriptionErrorToast(true);
            }
          }}
        />
      </PreferencesList>
      <PreferencesList
        preferencesByApp={props.channelPreferences?.preferencesByApp}
        type={Type.TRANSACTIONAL}
        onChangeItem={props.onChangePreference}
        channel={Channel.EMAIL}
      />
    </div>
  );
};
