import React, { FC } from 'react';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { membersAreaSettingsClickOnToggle } from '@wix/bi-logger-identity-data/v2';
import { Text } from 'wix-ui-tpa/cssVars';
import { st, classes } from '../../Widget.st.css';
import {
  Channel,
  Type,
} from '@wix/ambassador-notifications-v1-notification-preferences/types';
import { AppPreferences } from '../../../../../types';
import { PreferenceItem } from './PreferenceItem';

interface Props {
  preferencesByApp?: AppPreferences[];
  type: Type;
  onChangeItem(id: string, mute: boolean): void;
  channel: Channel;
  disabled?: boolean;
  children?: React.ReactElement;
}

export const PreferencesList: FC<Props> = ({
  preferencesByApp,
  type,
  onChangeItem,
  channel,
  disabled,
  children,
}) => {
  const { t } = useTranslation();
  const bi = useBi();

  const shownApps = preferencesByApp?.filter(({ preferences }) =>
    preferences.some((p) => p.topicType === type),
  );

  return shownApps?.length ? (
    <div className={classes.preferencesContainer}>
      <Text className={st(classes.titleText, classes.preferencesTitle)}>
        {t(`app.widget.${type}.title`)}
      </Text>
      {children}
      {shownApps?.map((app) => (
        <div className={classes.appContainer}>
          <div className={classes.appNameContainer}>
            <Text className={st(classes.titleText, classes.app)}>
              {app.appDisplayName}
            </Text>
          </div>
          {app.preferences
            .filter((p) => p.topicType === type)
            .map((p) => (
              <PreferenceItem
                preference={p}
                onClick={(mute) => {
                  bi.report(
                    membersAreaSettingsClickOnToggle({
                      title: app.appDisplayName,
                      toggleName: p.displayName,
                      toggle_state: !mute,
                      channel,
                    }),
                  );
                  onChangeItem(p.id, mute);
                }}
                disabled={disabled}
              />
            ))}
        </div>
      ))}
    </div>
  ) : null;
};
